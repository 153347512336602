<template>
  <div>
    <a-divider>Kartu Soal Guide</a-divider>
    <a-collapse accordion>
      <a-collapse-panel key="1" header="Sheet Identitas">
        <p>Pastikan anda mengisi semua kolom yang tersedia sesuai dengan identitas anda.</p>
      </a-collapse-panel>
      <a-collapse-panel key="2" header="Sheet Penyusunan (Umum)">
        <ul class="text-dark">
          <li>Pastikan anda mengisi seluruh kolom yang telah disediakan template.</li>
          <li>
            Pastikan jumlah baris soal yang diinginkan sesuai dengan jumlah soal yang ada di
            <span
              class="text-primary"
            >Sheet Identitas</span>.
          </li>
          <li>
            Jika baris soal kosong / tidak sesuai dengan jumlah soal, maka akan dianggap
            <span
              class="text-danger"
            >empty</span>, Anda harus mengecek kembali dokumen kartu soal.
          </li>
          <li>
            Jika soal/jawaban memiliki
            <span class="text-primary">gambar</span>, harap tidak memasukannya ke dalam dokumen kartu soal. Melainkan memasukan
            <span
              class="text-primary"
            >gambar</span> melalui sistem CBT.
          </li>
          <li>
            Untuk keseluruhan
            <span class="text-danger">bobot soal</span>, pastikan memiliki total bobot 100, jika bobot tidak sama dengan 100 maka kartu soal tidak akan diterima
          </li>
        </ul>
      </a-collapse-panel>
      <a-collapse-panel key="3" header="Sheet Penyusunan (Pilihan Ganda)">
        <ul class="text-dark">
          <li>
            Untuk soal tipe
            <span class="text-primary">pilihan ganda</span>, pastikan memiliki jawaban dan kunci jawaban.
          </li>
          <li>Pastikan mengisi kolom jawaban sebagai opsi jawaban dengan template seperti berikut :</li>
          <p class="font-weight-bold">a. Jawaban a</p>
          <p class="font-weight-bold">b. Jawaban b</p>
          <p class="font-weight-bold">c. Jawaban c</p>
          <p class="font-weight-bold">d. Jawaban d</p>
          <li>
            Disarankan untuk
            <span class="text-danger">tidak mengubah</span> template opsi jawaban diatas agar tidak terjadi kesalahan teknis di kemudian hari.
          </li>
          <li>
            Pastikan mengisi kolom
            <span class="text-primary">kunci jawaban</span> dengan huruf depan (tanpa titik) dari opsi jawaban
          </li>
        </ul>
      </a-collapse-panel>
      <a-collapse-panel key="4" header="Sheet Penyusunan (Essay)">
        <p>
          Untuk soal tipe
          <span class="text-primary">essay</span>, silahkan hanya mengisi uraian soal saja.
        </p>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
export default {

}
</script>

<style>
</style>
